<template>
  <div class="login-container">
    <el-card class="login-form">
      <el-form ref="loginFormRules" :rules="loginFormRules" :model="formInfo">
      <div class="title">
        南大教育报名管理系统
      </div>
      <el-form-item prop="username">
        <el-input v-model="formInfo.username" placeholder="账号">
          <i slot="prefix" class="iconfont icon-user-fill"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="formInfo.password" type="password" placeholder="密码" show-password>
          <i slot="prefix" class="iconfont icon-lock-fill"></i>
        </el-input>
      </el-form-item>
      <el-button type="primary" @click="loginHandle">登录</el-button>
    </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formInfo: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('login', ['login']),
    /* 用户登录 */
    loginHandle () {
      this.$refs.loginFormRules.validate(valid => {
        if (!valid) return false
        /* 请求登录接口 */
        this.login(this.formInfo).then(res => {
          window.sessionStorage.setItem('token', res.token)
          this.$router.push('/home')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  min-height: 100vh;
  background-image: url('/login-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login-form {
    position: absolute;
    width: 400px;
    max-width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      text-align: center;
      margin-bottom: 40px;
      font-size: 24px;
      color: #646cff;
      letter-spacing: 10px;
      font-weight: bold;
    }
    .el-button {
      width: 100%;
      background-color: #646cff;
      margin-top: 20px;
      border: none;
      &:hover {
        background-color: #898BFF;
      }
    }
    .el-form-item {
      background: rgba(0,0,0,.1);
      border: 1px solid hsla(0,0%,100%,.1);
      border-radius: 5px;
    }
  }
}
</style>
